import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const headerStyles = makeStyles(() => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },

  button: {
    fontSize: '1.3rem',
    textDecoration: 'none',
    color: COLOR_WHITE,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    // margin: '0 1vw',
    margin: '0px 20px 0px 0px',
    transition: 'all 200ms ease-in',
    position: 'relative',
    // height: '36px',
  },
  '&after': {
    position: 'absolute',
    bottom: '2px',
    left: '0px',
    right: '0px',
    width: '0%',
    content: '.',
    color: 'transparent',
    background: '#ea5b27',
    height: '2px',
    transition: 'all 0.3s ease-in',
  },
  '&hover': {
    color: COLOR_WHITE,
    after: {
      width: '100%',
    },
  },
  buttontext: {
    color: '#080c2d',
    fontSize: '1.2rem',
    fontWeight: '700',
    textTransform: 'none',
    margin: '10px',
    textAlign: 'center',
  },
  buttonPosition: {
    padding: '10px 10px 10px 10px',
  },
  buttonPosition1: {
    padding: '8px 10px 0px 10px',
    color: COLOR_WHITE,
  },
}));
