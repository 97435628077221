import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/styles';

export const TextOnlyTooltip = withStyles({
  tooltip: {
    color: '#f40707',
    backgroundColor: 'transparent',
    maxWidth: 350,
    fontSize: '2em',
    paddingTop: '5px',
  },
})(Tooltip);
