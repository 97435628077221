import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Paper, Typography } from '@material-ui/core';

import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

import LanguageIcon from '@material-ui/icons/Language';

import clsx from 'clsx';

import RegistrierungsButton from '../../Button/registrierungsButton';
import LanguageImageEn from '../LanguageEn';
import LanguageImageDe from '../LanguageDe';

import { headerStyles } from '../styles';
import { useNavItemStyles } from './styles';
import { TextOnlyTooltip } from './components/TextOnlyTooltip';

export const NavbarLinks = (props) => {
  const { scrollY, navbarOpen, dark } = props;

  const { buttonPosition, buttonPosition1, buttontext } = headerStyles();
  const { navItem, navItemLink } = useNavItemStyles({ scrollY, navbarOpen, dark });
  const { t } = useTranslation();
  const { changeLanguage } = useI18next();

  return (
    <>
      <Link partiallyActive className={clsx(navItem, navItemLink)} to="/product" activeStyle={{ color: '#ea5b27' }}>
        {t('trans.product')}
      </Link>
      <Link partiallyActive className={clsx(navItem, navItemLink)} to="/prices" activeStyle={{ color: '#ea5b27' }}>
        {t('trans.prices')}
      </Link>
      <Link partiallyActive className={clsx(navItem, navItemLink)} to="/faq" activeStyle={{ color: '#ea5b27' }}>
        {t('trans.faq')}
      </Link>
      <Link partiallyActive className={clsx(navItem, navItemLink)} to="/contact" activeStyle={{ color: '#ea5b27' }}>
        {t('trans.contact')}
      </Link>

      <TextOnlyTooltip
        title={
          <Paper>
            <Box>
              <Typography className={buttontext}>{t('trans.chooseYourLanguage')}</Typography>
            </Box>
            <Box mt={2}>
              <Button onClick={() => changeLanguage('de')}>
                <LanguageImageDe />
                <Box ml={3}>Deutschland - Deutsch</Box>
              </Button>
              <Button onClick={() => changeLanguage('en')}>
                <LanguageImageEn />
                <Box ml={3}>United Kingdom - English</Box>
              </Button>
            </Box>
          </Paper>
        }
        interactive
      >
        <Box className={clsx(buttonPosition1, navItemLink)}>
          <LanguageIcon />
        </Box>
      </TextOnlyTooltip>

      <Box className={buttonPosition}>
        <RegistrierungsButton />
      </Box>
    </>
  );
};

NavbarLinks.propTypes = {
  scrollY: PropTypes.number.isRequired,
};
