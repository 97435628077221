import { Typography } from '@material-ui/core';
import React from 'react';
import { useListItemLinkStyles } from './styles';

const getClassName = (level, classes) => {
  switch (level) {
    case 2:
      return classes.head2;
    case 3:
      return classes.head3;
    default:
      return null;
  }
};

export const ListItemLink = ({ id, title, isActive, level = 2, handleLinkClick }) => {
  const classes = useListItemLinkStyles();

  return (
    <Typography
      href={`#${id}`}
      variant="h5"
      component="a"
      className={`${isActive ? classes.active : ''} ${getClassName(level, classes)} ${classes.common}`}
      onClick={(e) => handleLinkClick(e, id)}
    >
      {title}
    </Typography>
  );
};
