import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { buttonStyles } from './styles';

const URL = 'https://app.d-simple.com/register?referrer=d-simple.com';

const RegistrierungsButton = ({ text }) => {
  const { t } = useTranslation();
  const classes = buttonStyles();

  return (
    <>
      <Button className={classes.button} href={URL} target="_blank" rel="noreferrer">
        {text || t('trans.registerNow')}
      </Button>
    </>
  );
};

export default RegistrierungsButton;
