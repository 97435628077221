import { makeStyles } from '@material-ui/styles';

export const useNavItemStyles = makeStyles(() => ({
  navItem: {
    fontSize: '1.5rem',
    textDecoration: 'none',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    margin: '0 15px',
    fontWeight: '700',
    position: 'relative',
    paddingBottom: '0px',
    '&:after': {
      position: 'absolute',
      bottom: '2px',
      left: '0',
      right: '0',
      width: '0%',
      content: '" "',
      color: 'transparent',
      background: '#ea5b27',
      height: '2px',
      display: 'block',
      margin: '0 auto',
      transition: 'all 0.3s ease-in',
    },
    '&:hover': {
      color: '#000038',
      '&::after': {
        width: '100%',
      },
    },
    '@media (max-width: 868px)': {
      padding: '15px 0',
      fontSize: '1.2rem',
      zIndex: '6',
      color: '#000038',
      '&:hover': {
        color: '#000038',
        '&::after': {
          width: '100%',
        },
      },
    },
  },
  navItemLink: {
    color: ({ scrollY, navbarOpen, dark }) => {
      if (dark) return 'rgba(29,29,29,1)';
      if (navbarOpen) return 'rgba(29,29,29,1)';
      if (typeof scrollY !== 'number') return 'rgba(255,255,255,1)';
      if (scrollY >= 50) return 'rgba(29,29,29,1)';
      const base = 255 - scrollY * (scrollY / 2);
      const baseColor = base >= 0 ? base : 0;
      const r = baseColor <= 29 ? 29 : baseColor;
      const g = r;
      const b = r;
      const a = 1;
      // return `rgba(${(255 / scrollY) * 10},${(255 / scrollY) * 10},${(255 / scrollY) * 15},1)`;
      return `rgba(${r}, ${g}, ${b}, ${a})`;

      // return 'rgba(0,0,56,1)';

      // return 'rgba(255,255,255,1)';
    },
  },
}));
