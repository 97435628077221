import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const scrollStyles = makeStyles(() => ({
  toTop: {
    zIndex: 102,
    position: 'fixed',
    bottom: '35px',
    backgroundColor: '#ea5b27',
    color: COLOR_WHITE,
    '&:hover, &.Mui-focusVisible': {
      transition: '0.4s',
      color: COLOR_WHITE,
      backgroundColor: '#ff652d',
    },
    right: '3%',
  },
}));
