import * as React from 'react';
import PropTypes from 'prop-types';

import './layout.css';

import Footer from '../Footer/footer';
import Navbar from '../Header/Navbar';
import Scroll from '../Scroll/scroll';

export const useCookieYes = () => {
  React.useEffect(() => {
    let timeoutId = null;
    let intervalId = null;

    const checkConsent = () => {
      const consentCookie = document.cookie.split('; ').find((row) => row.startsWith('cookieyes-consent='));
      if (!consentCookie) {
        return; // Cookie not found
      }

      const consentParts = consentCookie.split('=')[1].split(',');
      const consentValue = consentParts.find((part) => part.trim() === 'consent:no');

      if (consentValue) {
        document.documentElement.classList.add('no-scroll');
      } else {
        document.documentElement.classList.remove('no-scroll');
      }

      clearInterval(intervalId);
    };

    // Initial check and setInterval to recheck
    checkConsent();
    intervalId = setInterval(checkConsent, 1000);

    // Set timeout to clear interval after 30 seconds
    timeoutId = setTimeout(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }, 30000); // 30 seconds

    // Event listener for consent update
    const handleConsentUpdate = () => {
      // Delayed check to wait for cky-hide class to potentially be added
      setTimeout(() => {
        const consentContainer = document.querySelector('.cky-consent-container');
        if (consentContainer && consentContainer.classList.contains('cky-hide')) {
          document.documentElement.classList.remove('no-scroll');
          clearInterval(intervalId); // Stop checking on consent update
          clearTimeout(timeoutId); // Clear timeout if consent is updated before 30 seconds
        }
      }, 500); // Adjust delay as needed
    };
    document.addEventListener('cookieyes_consent_update', handleConsentUpdate);

    // Cleanup interval and event listener on unmount
    return () => {
      clearInterval(intervalId);
      document.removeEventListener('cookieyes_consent_update', handleConsentUpdate);
      clearTimeout(timeoutId); // Clear timeout on unmount
    };
  }, []);
};

export const Layout = ({ children }) => {
  useCookieYes();
  return (
    <>
      <Scroll showBelow={350} />
      <Navbar />

      <div
        style={{
          margin: '0 auto',
          maxWidth: '100%',
          padding: '0px 0px 0px 0px ',
        }}
      >
        <main>{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
