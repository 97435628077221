import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import { BgImage } from 'gbimage-bridge';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans, Link, useTranslation } from 'gatsby-plugin-react-i18next';
import Footer from '../../Footer/footer';
import Navbar from '../../Header/Navbar';
import Scroll from '../../Scroll/scroll';
import { TableOfContent } from '../../TableOfContents/TableOfContent';

import { useLandingPageLayoutStyles } from './styles';
import { useCookieYes } from '../layout';

export const LandingPageLayout = ({ children, bannerImage, bannerTitle, bannerDescription }) => {
  const classes = useLandingPageLayoutStyles();
  const { t } = useTranslation('common');
  useCookieYes();

  return (
    <>
      <Scroll showBelow={350} />
      <Navbar />

      <div className={classes.mainWrap}>
        <main>
          {bannerImage && <BgImage image={bannerImage} className={classes.bannerImage} />}

          <Container className={classes.bannerContainerWrap}>
            <Box className={classes.bannerCardWrap}>
              <Paper className={classes.bannerPaper}>
                <Box mb={1}>
                  <Typography className={classes.bannerTitle} variant="h1">
                    {bannerTitle}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">{bannerDescription}</Typography>
                </Box>
              </Paper>
            </Box>
          </Container>

          <Container>
            <Box pt={4}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={3}>
                  <TableOfContent />
                </Grid>
                <Grid item xs={12} md={9}>
                  {children}
                </Grid>
              </Grid>
            </Box>
          </Container>

          <Box className={classes.landingPageFooterWrap}>
            <Container>
              <Grid container>
                <Grid item lg={12} md={12} xs={12}>
                  <Typography>
                    <Trans t={t} i18nKey="content.umoFooter1" components={[<Link key="0" to="/product" />]} />
                  </Typography>
                  <Typography>
                    <Trans
                      t={t}
                      i18nKey="content.umoFooter2"
                      components={[<Link key="0" to="/faq" />, <Link key="1" to="/contact" />]}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </main>
        <Footer />
      </div>
    </>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  bannerImage: PropTypes.object,
  bannerTitle: PropTypes.string,
  bannerDescription: PropTypes.string,
};

LandingPageLayout.defaultProps = {
  bannerImage: {},
  bannerTitle: '',
  bannerDescription: '',
};
