import { makeStyles } from '@material-ui/core/styles';
import {
  SECONDARY_MAIN_COLOR,
  PRIMARY_TEXT_COLOR,
  PRIMARY_TEXT_COLOR_LIGHT,
  NAVBAR_HEIGHT,
} from '../../theme/constants';

export const useTableOfContentStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: NAVBAR_HEIGHT + theme.spacing(2),
  },
  listItem: {
    marginBottom: 0,
    marginTop: theme.spacing(1),
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: theme.spacing(1),
    '&:first-child': {
      marginTop: 0,
      paddingTop: 0,
    },
  },
  listItemNested: {
    marginTop: theme.spacing(0.5),
  },
  activeBorder: {
    borderLeft: `1px solid ${theme.palette.secondary.main}`,
  },
}));

export const useListItemLinkStyles = makeStyles((theme) => ({
  head2: {},
  head3: {
    marginLeft: theme.spacing(1.5),
    listStyleType: 'circle',
  },
  common: {
    color: PRIMARY_TEXT_COLOR,
    '&:hover': {
      color: PRIMARY_TEXT_COLOR_LIGHT,
    },
  },
  active: {
    color: SECONDARY_MAIN_COLOR,
    '&:hover': {
      color: SECONDARY_MAIN_COLOR,
    },
  },
}));
