import { makeStyles } from '@material-ui/core/styles';

export const buttonStyles = makeStyles(() => ({
  button: {
    letterSpacing: '1.9px',
    backgroundColor: '#ea5b27',
    border: 1,
    borderRadius: 4,
    color: 'white',
    height: 36,
    width: 200,
    padding: '0px 70px 0px 70px',
    '&:hover': {
      backgroundColor: '#ff8c54',
      boxShadow: 'none',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
    },
    '&:link': {
      whiteSpace: 'nowrap',
    },
    '&:visited': {
      whiteSpace: 'nowrap',
    },
    '&:a': {
      whiteSpace: 'nowrap',
    },
  },

  buttonForFree: {
    letterSpacing: '1.9px',
    backgroundColor: '#ea5b27',
    border: 1,
    borderRadius: 4,
    color: 'white',
    height: 36,
    width: 300,
    padding: '0px 70px 0px 70px',
    '&:hover': {
      backgroundColor: '#ff8c54',
      boxShadow: 'none',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
    },
    '&:link': {
      whiteSpace: 'nowrap',
    },
    '&:visited': {
      whiteSpace: 'nowrap',
    },
    '&:a': {
      whiteSpace: 'nowrap',
    },
  },
}));
