import { makeStyles } from '@material-ui/core/styles';
import { COLOR_CARD_WHITE, COLOR_WHITE } from '../../../theme/constants';

export const useLandingPageLayoutStyles = makeStyles((theme) => ({
  mainWrap: {
    margin: '0 auto',
    maxWidth: '100%',
    padding: '0px 0px 0px 0px ',
  },
  bannerImage: {
    height: '400px',
  },
  bannerContainerWrap: {
    position: 'relative',
    marginBottom: '120px',
  },
  bannerCardWrap: {
    position: 'absolute',
    maxWidth: '710px',
    top: '-140px',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      top: '-100px',
      maxWidth: '100%',
      left: theme.spacing(1),
      right: theme.spacing(1),
    },
    [theme.breakpoints.down('400px')]: {
      top: '-60px',
    },
  },
  bannerPaper: {
    padding: `${theme.spacing(5)}px ${theme.spacing(4)}px`,
  },
  bannerTitle: {
    hyphens: 'auto',
  },
  headerImageWrap: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4rem 1rem 1rem 0.5rem',
  },
  boxImagesHead: {
    minHeight: '280px',
    maxHeight: '280px',
  },
  siteTitle: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    marginTop: '50px',
    animation: 'textwelle 0.6s',
  },
  siteTitleContent: {
    fontSize: '1.3rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginLeft: '0px',
    animation: 'textwelle 0.5s',
  },
  landingPageFooterWrap: {
    background: COLOR_CARD_WHITE,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));
