import PropTypes from 'prop-types';
import React from 'react';

import Footer from '../../Footer/footer';
import Navbar from '../../Header/Navbar';
import Scroll from '../../Scroll/scroll';

import { useProductPageLayoutStyles } from './styles';

export const ProductPageLayout = ({ children }) => {
  const classes = useProductPageLayoutStyles();

  return (
    <>
      <Scroll showBelow={350} />
      <Navbar dark />
      <main className={classes.mainWrap}>{children}</main>
      <Footer />
    </>
  );
};

ProductPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
