import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../../theme/constants';

export const useProductPageLayoutStyles = makeStyles(() => ({
  mainWrap: {
    margin: '0 auto',
    maxWidth: '100%',
    padding: '0px 0px 0px 0px ',
    backgroundColor: COLOR_WHITE,
    paddingTop: '150px',
  },
}));
