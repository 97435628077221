// src/hooks.js
import { useEffect, useState, useRef } from 'react';
import { NAVBAR_HEIGHT } from '../../theme/constants';

// REF: https://www.emgoto.com/react-table-of-contents/
export function useHeadsObserver() {
  const [activeId, setActiveId] = useState('');

  const headingElementsRef = useRef({});
  useEffect(() => {
    const headingElements = Array.from(document.querySelectorAll('h2, h3'));

    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        // eslint-disable-next-line no-param-reassign
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) => headingElements.findIndex((heading) => heading.id === id);

      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
          (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id),
        );
        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, {
      rootMargin: `${NAVBAR_HEIGHT}px 0px -40% 0px`,
    });

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, []);

  return { activeId };
}
