import { PropTypes } from 'prop-types';
import React from 'react';

const LogoImage = ({ src }) => <img alt="d-sinple Logo" src={src} height="40px" />;

LogoImage.propTypes = {
  src: PropTypes.string.isRequired,
};

export default LogoImage;
