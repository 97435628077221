import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const footerStyles = makeStyles(() => ({
  footer: {
    whiteSpace: 'nowrap',
    backgroundColor: '#F9FAFE',
    padding: '1em 2em 3em 10%',
    borderTop: '1px solid #cacece',
  },
  header: {
    fontSize: '1.2rem',
    fontWeight: '600',
    textAlign: 'left',
    color: '#080c2d',
    paddingBottom: '0.5em',
  },
  contentFooter: {
    fontSize: '0.9rem',
    fontWeight: '400',
    textAlign: 'left',
    color: '#545372',
  },
  contentBold: {
    fontSize: '0.9rem',
    fontWeight: '600',
    textAlign: 'left',
    color: '#080c2d',
  },
  contentmail: {
    fontSize: '0.9rem',
    fontWeight: '400',
    textAlign: 'left',
    color: '#545372',
  },
  image: {
    height: '63px',
    marginBottom: '10px',
  },
  copyrightContainer: {
    fontSize: '1.1rem',
    fontWeight: '400',
    textAlign: 'center',
    color: COLOR_WHITE,
    marginTop: '0px',
    padding: '15px 1em 0em 1em',
  },
  copyrightBox: {
    backgroundColor: '#080c2d',
    height: '60px',
  },

  registrierung: {
    fontSize: '0.8rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginTop: '20px',
  },
}));
