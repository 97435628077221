import React, { useEffect, useState } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { scrollStyles } from './styles';

const Scroll = ({ showBelow }) => {
  const [show, setShow] = useState(!showBelow);
  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else if (show) setShow(false);
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener('scroll', handleScroll);
      // habe ich zurück geändert, da sonst der Button nicht ausgeblendet wird
      return () => window.removeEventListener('scroll', handleScroll);
    }
  });

  const classes = scrollStyles();
  return (
    <div>
      {show && (
        <IconButton onClick={handleClick} className={classes.toTop}>
          <ExpandLessIcon />
        </IconButton>
      )}
    </div>
  );
};
Scroll.propTypes = {
  showBelow: PropTypes.number.isRequired,
};

export default Scroll;
