import { Box } from '@material-ui/core';
// import { Link } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import NavbarLinks from './NavbarLinks';
import LogoSrc from '../../images/logo.png';
import LogoWhiteSrc from '../../images/Logo_white.png';
import { COLOR_WHITE, NAVBAR_HEIGHT } from '../../theme/constants';

const useStyles = makeStyles(() => ({
  navigation: {
    height: ({ scrollY }) => `${scrollY >= 50 ? 75 : 100 - scrollY / 2}px`,
    marginBottom: ({ scrollY }) => `${scrollY >= 50 ? -75 : (100 - scrollY / 2) * -1}px`,
    display: 'flex',
    // eslint-disable-next-line max-len
    backgroundColor: (props) =>
      props.navbarOpen ? '#ebf0f4' : `rgba(255, 255, 255, ${props.scrollY <= 50 ? props.scrollY / 50 : 1})`,
    // backgroundColor: 'rgba(3, 41, 99, 0.2)',
    // backgroundColor: 'rgba(0, 0, 0, 0.2)',
    // backgroundColor: 'rgba(200, 200, 200, 0.4)',
    justifyContent: 'space-between',
    textTransform: 'none',
    // borderBottom: '1px solid #ea5b27',
    margin: '0 auto',
    padding: '0 5vw',
    alignSelf: 'center',
    position: 'fixed',
    zIndex: '999',
    width: '100%',

    transition: (props) => (props.navbarOpen ? 'none' : 'background-color 500ms ease-out, box-shadow 500ms ease-out'),
    '@media (min-width: 768px)': {
      position: 'sticky',
      height: `${NAVBAR_HEIGHT}px`,
      top: '0px',
      left: '0',
      right: '0',
    },
  },
  navigationScrolled: {
    // backgroundColor: 'rgba(3, 41, 99, 1)',
    // backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: ({ scrollY }) => `0 3px 5px rgb(57 63 72 / ${scrollY >= 50 ? 30 : scrollY * 0.6}%)`,
    // borderBottom: '0px',
  },
  baseImage: {
    position: 'absolute',
    left: '0',
    width: '100%',
    height: 'auto',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  imageBlack: {
    // eslint-disable-next-line no-nested-ternary
    opacity: ({ scrollY, navbarOpen, dark }) => (dark ? 1 : navbarOpen ? 1 : 0 + scrollY / 50),
  },
  imageWhite: {
    opacity: ({ scrollY, dark }) => (dark ? 0 : 1 - scrollY / 50),
  },
}));

const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10px;

  @media (max-width: 960px) {
    display: flex;
  }
`;

const Navbox = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 960px) {
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    padding-top: 5vh;
    background-color: #ebf0f4;
    top: 85px;
    left: ${(props) => (props.open ? '0' : '-100%')};
  }
`;

const Hamburger = styled.div`
  background-color: ${(props) => (props.open || props.dark ? 'rgba(29,29,29,1)' : '#efefef')};
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};
  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: ${(props) => (props.open || props.dark ? 'rgba(29,29,29,1)' : '#efefef')};
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }
  ::before {
    transform: ${(props) => (props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)')};
    top: -10px;
  }
  ::after {
    opacity: ${(props) => (props.open ? '0' : '1')};
    transform: ${(props) => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`;

const LogoItemLink = styled(Link)`
  display: block;
  position: relative;
  height: 100%;
  text-decoration: none;
  &:hover {
    color: ${COLOR_WHITE};
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    color: ${COLOR_WHITE};
    text-decoration: none;
  }
`;

const Navbar = ({ dark = false }) => {
  const [scrollY, setScrollY] = useState(0);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const classes = useStyles({ scrollY, navbarOpen, dark });

  function logit() {
    setScrollY(window.pageYOffset);
  }

  useEffect(() => {
    window.addEventListener('scroll', logit);

    return () => {
      window.removeEventListener('scroll', logit);
    };
  }, []);

  useEffect(() => {
    if (navbarOpen) {
      document.body.classList.add('fixed');
    } else {
      document.body.classList.remove('fixed');
    }

    return () => {
      document.body.classList.remove('fixed');
    };
  }, [navbarOpen]);

  // dispatch scroll event when page loaded to get correct styling of header
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('scroll'));
    }, 1000);
  }, []);

  return (
    <Box className={clsx(classes.navigation, scrollY > 1 && classes.navigationScrolled)}>
      <Box height="100%" flex="0 0 180px" p={`${scrollY / 5 >= 20 ? 20 : scrollY / 5}px`}>
        <LogoItemLink to="/">
          {navbarOpen ? (
            <img className={clsx(classes.baseImage, classes.imageBlack)} alt="d-simple Logo" src={LogoSrc} />
          ) : (
            <>
              <img className={clsx(classes.baseImage, classes.imageBlack)} alt="d-simple Logo" src={LogoSrc} />
              <img
                className={clsx(classes.baseImage, classes.imageWhite)}
                alt="d-sinple Logo White"
                src={LogoWhiteSrc}
              />
            </>
          )}
        </LogoItemLink>
      </Box>

      <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
        <Hamburger open={navbarOpen} dark={dark} />
      </Toggle>

      <Navbox open={navbarOpen}>
        <NavbarLinks dark={dark} navbarOpen={navbarOpen} scrollY={scrollY} />
      </Navbox>
    </Box>
  );
};

export default Navbar;
