import { List, ListItem } from '@material-ui/core';

import React from 'react';

import { useTableOfContentStyles } from './styles';
import { ListItemLink } from './ListItemLink';
import { useHeadsObserver } from './useHeadsObserver';
import { useHeadingsData } from './useHeadingsData';

const scrollIntoViewWithOffset = (selector, offset) => {
  window.scrollTo({
    behavior: 'smooth',
    top:
      document.querySelector(selector).getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
  });
};

export const TableOfContent = () => {
  const classes = useTableOfContentStyles();

  const { activeId } = useHeadsObserver();

  const { nestedHeadings } = useHeadingsData();

  const handleLinkClick = (e, id) => {
    e.preventDefault();
    if (id) {
      scrollIntoViewWithOffset(`#${id}`, 92);
    } else {
      // eslint-disable-next-line no-console
      console.error('Attempted to click on Table of Contents link but could not find heading with id');
    }
  };

  return (
    <>
      <nav className={classes.root} aria-label="Table of contents">
        <List>
          {nestedHeadings.map((heading) => {
            const isParentActive = heading.id === activeId;
            const isAnyChildActive = heading.items.find((item) => item.id === activeId);

            return (
              <ListItem
                key={heading.id}
                dense
                disableGutters
                className={`${classes.listItem} ${isParentActive || isAnyChildActive ? classes.activeBorder : ''}`}
              >
                <ListItemLink
                  title={heading.title}
                  id={heading.id}
                  isActive={isParentActive}
                  isAnyChildActive={isAnyChildActive}
                  handleLinkClick={handleLinkClick}
                />
                {(isParentActive || isAnyChildActive) && heading.items.length > 0 && (
                  <List disablePadding dense>
                    {heading.items.map((child) => {
                      return (
                        <ListItem dense disableGutters key={child.id} className={classes.listItemNested}>
                          <ListItemLink
                            title={child.title}
                            id={child.id}
                            isActive={child.id === activeId}
                            handleLinkClick={handleLinkClick}
                            level={3}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                )}
              </ListItem>
            );
          })}
        </List>
      </nav>
    </>
  );
};
